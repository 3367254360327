<template>
  <div class="modal-wrap">
    <div class="modal-inner">
      <span v-on:click="closeComponent" class="modal-close">X</span>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <div class="bordered pad">
            <h1>Assign {{ user.first_name }} {{ user.last_name }} to a tutor group</h1>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pad-top">
        <div class="cell small-12">
          <div class="bordered pad">
            <select v-model="tutorGroup">
              <option
                v-for="(group, index) in tutorGroups"
                v-bind:key="index"
                v-bind:value="group.id">
                {{ group.name }}
              </option>
            </select>
            <button v-on:click="assignToGroup" class="button">
              Assign
            </button>
            <p v-if="message.length > 0" class="pad-top">{{ message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'AssignToGroup',
  props: [
    'user',
  ],
  data() {
    return {
      tutorGroups: [],
      tutorGroup: 0,
      message: '',
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  methods: {
    closeComponent() {
      this.$emit('closeComponent');
    },
    getTutorGroups() {
      axios.post(`/tutorGroups/getGroups.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.tutorGroups = response.data.tutorGroups;
        });
    },
    assignToGroup() {
      const postData = {};
      postData.user = this.user;
      postData.tutorGroup = this.tutorGroup;
      axios.post(`/tutorGroups/assignToGroup.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, postData)
        .then((response) => {
          if (response.data.response === true) {
            this.closeComponent();
          } else {
            this.message = 'The user was not assigned to the group - please check and try again';
          }
        });
    },
  },
  mounted() {
    this.getTutorGroups();
  },
};
</script>
