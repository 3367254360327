<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>All users</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
            <h3>Admins</h3>
            <ul class="user-list">
                <li v-for="(user, index) in users.admins" v-bind:key="index">
                    Name: {{ user.first_name }} {{ user.last_name }}
                    Email: {{ user.email_address }}
                    &nbsp;|&nbsp;<router-link v-bind:to="`/edit-user/${user.id}`">Edit</router-link>
                </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
            <h3>IQAs</h3>
            <ul class="user-list">
                <li v-for="(user, index) in users.iqas" v-bind:key="index">
                    Name: {{ user.first_name }} {{ user.last_name }}
                    Email: {{ user.email_address }}
                    &nbsp;|&nbsp;<router-link v-bind:to="`/edit-user/${user.id}`">Edit</router-link>
                </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
            <h3>Tutors</h3>
            <ul class="user-list">
                <li v-for="(user, tutorIndex) in users.tutors" v-bind:key="tutorIndex">
                    Name: {{ user.first_name }} {{ user.last_name }}
                    Email: {{ user.email_address }}
                    &nbsp;|&nbsp;<router-link v-bind:to="`/edit-user/${user.id}`">Edit</router-link>
                    &nbsp;|&nbsp;<span class="link" v-on:click="assignTutor(tutorIndex)">
                      Assign to group
                    </span>
                </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
            <h3>Learners</h3>
            <div class="grid-x">
              <div class="cell small-12 medium-3">
                <input type="text" v-model="searchName" placeholder="Search by surname i.e. Smith">
              </div>
              <div class="cell small-12 medium-4">
                <button
                  v-on:click="getUsers()"
                  style="position:relative; top: 3px; left: 10px;"
                  class="button">
                  Search learners
                </button>
                &nbsp;
                <button
                  v-on:click="clearSearch()"
                  style="position:relative; top: 3px; left: 10px;"
                  class="button">
                  Clear search
                </button>
              </div>
            </div>
            <ul class="user-list">
                <li v-for="(user, learnerIndex) in users.learners" v-bind:key="learnerIndex">
                    Name: {{ user.first_name }} {{ user.last_name }}
                    Email: {{ user.email_address }}
                    &nbsp;|&nbsp;<router-link v-bind:to="`/view-user/${user.id}`">View</router-link>
                    &nbsp;|&nbsp;
                    <router-link v-bind:to="`/admin-add-file/${user.id}`">
                      Add file
                    </router-link>
                    &nbsp;|&nbsp;<router-link v-bind:to="`/edit-user/${user.id}`">Edit</router-link>
                    &nbsp;|&nbsp;<span class="link" v-on:click="assignLearner(learnerIndex)">
                      Assign to group
                    </span>
                </li>
            </ul>
        </div>
      </div>
    </div>
    <app-assign
      v-if="assignCurrentUser === true"
      v-bind:user="currentUser"
      v-on:closeComponent="assignCurrentUser = false" />
  </div>
</template>

<script>
import Assign from '@/components/AssignToGroup.vue';
import axios from '../axios';

export default {
  name: 'ListUsers',
  components: {
    appAssign: Assign,
  },
  data() {
    return {
      users: [],
      currentUser: {},
      searchName: '',
      assignCurrentUser: false,
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getUsers() {
      axios.get(`/users/getUsers.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&searchName=${this.searchName}`)
        .then((response) => {
          this.users = response.data.users;
        });
    },
    assignLearner(userIndex) {
      this.currentUser = this.users.learners[userIndex];
      this.assignCurrentUser = true;
    },
    assignTutor(userIndex) {
      this.currentUser = this.users.tutors[userIndex];
      this.assignCurrentUser = true;
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
